export default {
    accessTokenKeyName: 'accessTokenApiit',
    refreshTokenKeyName: 'refreshTokenApiit',
    basicAuthString: 'Y2V5ZW50cmE6Y2V5ZW50cmE=',
    role: 'apiitRole',
    username: 'apiitUsername',
    user: 'apiitUserLoggedIn',

    studentRole: 'STUDENT',
    itAManagerRole: 'IT_MANAGER',
    counsellorRole: 'COUNSELLOR',
    hosRole: 'HEAD_OF_SCHOOL',
    haaRole: 'HEAD_OF_ACADEMIC_ADMIN',
    aaRole: 'ACADEMIC_ADMINISTRATOR',
    hofRole: 'HEAD_OF_FINANCE',
    hocRole: 'HEAD_OF_COUNSELLOR',
    fmRole: 'FINANCE_MANAGER',
    feRole: 'FINANCE_EXECUTIVE',
    maaRole: 'MANAGER_ACADEMIC_ADMIN',
    lecturer: 'LECTURER',
    guest: 'GUEST',

    inquiryId: "INQ_ID",

    // payment gateway required ids
    accessKey: '746998dfe863378ea8d50447d4b1d25b',
    profileId: '7E8B19D7-EECE-4044-B594-0E937CC59636',
    secretKey: '1c750e0ab9284d63b9adca34eba018959229fdb48f624323b64199ab86588da21b01ffc3e1354b9f91999dca31a667a8dff5d79597bf41b793b754a12b888b85ff0b66e8df754c5e988ddf9a7558b343149e48487c3b4eda8706ec6a9e71bcf3080e26ec229b4541a3c03acc69188c0464aa9d26333f421bbedba2e003ba8e59',
    signedFieldNames: 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency'
}
