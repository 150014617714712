// You can customize the template with the help of this file
import faviconDefault from '../assets/images/logo/favicon.png'
import faviconWhite from '../assets/images/logo/favicon-white.png'
//Template config options
const themeConfig = {
    app: {
        appName: '',
        appLogoImage: require('@src/assets/images/logo/logo.png').default,
        appLogoDarkImage: require('@src/assets/images/logo/logo-dark.png').default,
        appLogoFavImage: require('@src/assets/images/logo/favicon.png').default,
        loginBgImage: require('@src/assets/images/pages/login-background.png').default,
        loginBtnImage: require('@src/assets/images/pages/ms-signIn-btn.png').default,
        logoIcon: require('@src/assets/images/logo/logo-icon.png').default,
        logoLetters: require('@src/assets/images/logo/logo-letters.png').default,
        faviconDefault,
        faviconWhite
    },
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: 'vertical', // vertical, horizontal
        contentWidth: 'boxed', // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: 'white' // BS color options [primary, success, etc]
        },
        footer: {
            type: 'static' // static, sticky, hidden
        },
        customizer: false,
        scrollTop: true // Enable scroll to top button
    }
}

export default themeConfig
