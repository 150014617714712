import {__DEMO__, __DEV__, __PROD__, __TESTING__,LOGIN_TYPES} from "@const"

const dev = 'http://localhost:3000'
const testing = 'https://apiit-sms.ceyentra.lk'
const staging = 'https://apiit-sms-staging.ceyentra.lk'
// const prod = 'https://apiit-sms-prod.ceyentra.lk'
const prod = 'https://sms.apiit.lk'
const demo = 'https://demo.unicloud360.com'

export const msalConfig = {
    auth: {
        // clientId: __PROD__ ? "bce1de47-5e5c-4ba3-84bb-76a018ddec69" : "6a44ed93-b8d1-4063-8efc-eea52c9b8407",
        clientId: __PROD__ ? "14845394-6e7a-49a0-87f6-76f7fa781d21" : "6a44ed93-b8d1-4063-8efc-eea52c9b8407",
        authority: __PROD__ ? "https://login.microsoftonline.com/4da585ed-d638-4e1c-bf37-02b73167e1b4" :
            "https://login.microsoftonline.com/8ad52752-28db-43b8-a514-511b3e176967",
        redirectUri: __DEV__ ? dev : __TESTING__ ? testing : __PROD__ ? prod : __DEMO__ ? demo : staging
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    // scopes: ["User.Read"]
    // scopes: [
    //     __PROD__ ? "api://bce1de47-5e5c-4ba3-84bb-76a018ddec69/APIIT_student_managent_System" :
    //         "api://6a44ed93-b8d1-4063-8efc-eea52c9b8407/NewApiit.ExampleScope"
    // ]
    scopes: [
        __PROD__ ? "api://14845394-6e7a-49a0-87f6-76f7fa781d21/APIIT_student_managent_System" :
            "api://6a44ed93-b8d1-4063-8efc-eea52c9b8407/NewApiit.ExampleScope"
    ]
}

export const loginView = {
    type: LOGIN_TYPES.microsoft
}

export const quickAction = {
    moodle: true,
    office365: true,
    externalInquiry: true,
    library: true
}

export const receiptTax = {
    taxAllow: true
}

export const batchPlan = {
    levelWiseOrientation: true
}

// don't change the order (if need have update util.js)
export const PAYMENT_INTERVALS = [
    {label: 'Monthly - 30 days ', value: '1-M-ANY'},
    {label: 'Annual - one time payment ', value: '12-M-ANY'},
    {label: '2 Semester - 3 months', value: '3-M-2'},
    {label: '2 Semester - 4 months', value: '4-M-2'},
    {label: '3 Semester - 3 months ', value: '3-M-3'},
    {label: '4 Semester - 4 months', value: '4-M-4'}
    // {label: '2 Semester - 6 months ', value: '6-M-2'},
    // {label: '1 Semester - 3 months', value: '3-M-1'},
    // {label: 'One time payment ', value: '1-M-1'}
]