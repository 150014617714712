export const __DEV__ = window.location.hostname === 'localhost'
export const __TESTING__ = window.location.hostname === 'apiit-sms.ceyentra.lk'
export const __STAGING__ = window.location.hostname === 'apiit-sms-staging.ceyentra.lk'
export const __DEMO__ = window.location.hostname === 'demo.unicloud360.com'
// export const __PROD__ = window.location.hostname === 'apiit-sms-prod.ceyentra.lk'
export const __PROD__ = window.location.hostname === 'sms.apiit.lk'

export const DOC_PDF = require('@src/assets/images/student/doc.png').default
export const DEFAULT_AVATAR = require('@src/assets/images/portrait/avatar-blank.png').default
export const EMPTY_FILE = require('@src/assets/images/counsellor/emptyFile.png').default
export const DEFAULT_RECOMMENDATION_LETTER = require('@src/assets/images/counsellor/letter.png').default
export const DEFAULT_RECOMMENDATION_LETTER_DARK = require('@src/assets/images/counsellor/letter-dark.png').default
export const DEFAULT_STUDENT_AGREEMENT = require('@src/assets/images/counsellor/agreement.png').default
export const DEFAULT_QUALIFICATION_RESULT_SHEET = require('@src/assets/images/counsellor/resultSheet.png').default
export const DEFAULT_QUALIFICATION_OTHER_ATTACHMENTS = require('@src/assets/images/counsellor/otherAttachment.png').default
export const FAVICON_ICON = require('@src/assets/images/logo/logo-icon.png').default
export const FAVICON_WHITE_ICON = require('@src/assets/images/logo/favicon-white.png').default
export const COUNSELLOR_DECLARATION = 'I, hereby declare and confirm the accuracy and truthfulness of the facts that I have herein disclosed or mentioned; if any of the facts disclosed herein shall become false or inaccurate, I accept to be prosecuted and punished under the quarantine rules and regulations of Sri Lanka.'
export const COURSE_TRANSFER_DECLARATION = '1. I declare that all the particulars provided in this form are complete and correct.\n' +
    '2. I undertake to pay all fees by the due date and to pay the administrative charge for late payments (late payment fee) where necessary as stipulated in the Fees and Enrolment Guide.\n' +
    '3. I understand that I will not be permitted to use APIIT facilities if payments are not made on the due date and that my studentship/ student status is subject to cancellation if the payment of fees is delayed by 45 days.\n' +
    '4. I have read and understood APIIT refund policy which is stated in the Fees and Enrolment Guide.\n' +
    '5. I agree to abide by APIIT Professional Code of Conduct as stated in the Student Handbook.'
export const IDENTIFIERS = ['Mr', 'Mrs', 'Miss', 'Dr']
export const STUDENT_ONBOARD_TOKEN = '/student-onboard/3f078484b1189f734d15e0f6de015a37970c18f76296cd3dcbd03853b5455a9c'
export const INQUIRY_TYPES = [
    {label: 'Phone In', value: 'PHONE_IN'},
    {label: 'Direct Phone-In', value: 'DIRECT_PHONE_IN'},
    {label: 'E-Mail', value: 'EMAIL'},
    {label: 'Walk-in', value: 'WALK_IN'},
    {label: 'FB Lead', value: 'FB_LEAD'},
    {label: 'Exhibition', value: 'EXHIBITION'},
    {label: 'Friends', value: 'FRIENDS'},
    {label: 'Hotline', value: 'HOTLINE'},
    {label: 'Open Day', value: 'OPEN_DAY'},
    {label: 'SIS', value: 'SIS'},
    {label: 'School Promotion', value: 'SCHOOL_PROMOTION'},
    {label: 'Univisitor', value: 'UNIVISITOR'},
    {label: 'Website', value: 'WEBSITE'},
    {label: 'Agent', value: 'AGENT'},
    {label: 'Database', value: 'DATABASE'}
]
export const FEE_TYPES = [
    {label: 'Registration Fee', value: 'REGISTRATION_FEE'},
    {label: 'Library Fee', value: 'LIBRARY_FEE'},
    {label: 'SU Fee', value: 'SU_FEE'},
    {label: 'Exemption Fee', value: 'EXEMPTION_FEE'},
    {label: 'Course Fee', value: 'COURSE_FEE'}
]
export const INQUIRY_STATUS = [
    {label: 'All', value: null},
    {label: 'Inquiry', value: 'INQUIRY'},
    {label: 'Followup', value: 'FOLLOWUP'},
    // {label: 'Application Shared', value: 'APPLICATION_SHARED'},
    // {label: 'Application Submitted', value: 'APPLICATION_SUBMITTED'},
    // {label: 'HOS Approval', value: 'HOS_APPROVAL'},
    // {label: 'HOA Approval', value: 'HOA_APPROVAL'},
    // {label: 'Offer Letter Shared', value: 'OFFER_LETTER_SHARED'},
    // {label: 'Course Details Shared', value: 'COURSE_DETAILS_SHARED'},
    // {label: 'Pending Payment', value: 'PENDING_PAYMENT'},
    {label: 'Not Interested', value: 'NOT_INTERESTED'},
    // {label: 'Enrollment', value: 'ENROLLMENT'},
    {label: 'Registration', value: 'REGISTRATION'},
    {label: 'Future Intake', value: 'FUTURE_INTAKE'},
    {label: 'Unassigned', value: 'UNASSIGNED'}
]
export const INQUIRY_PROSPECT_STATUS = [
    {label: 'Followup', value: 'FOLLOWUP'},
    {label: 'Future Intake', value: 'FUTURE_INTAKE'},
    {label: 'Not Interested', value: 'NOT_INTERESTED'}
]
export const INQUIRY_PROSPECT_STATUS2 = [
    {label: 'Followup', value: 'FOLLOWUP'},
    {label: 'Not Interested', value: 'NOT_INTERESTED'}
]
export const INTAKES = [
    {intakeId: 0, intakeCode: 'ABC-2314'},
    {intakeId: 1, intakeCode: '20211012'},
    {intakeId: 2, intakeCode: 'GDSE-45'},
    {intakeId: 3, intakeCode: 'ABC-4321'}
]
export const LEVELS = [
    {levelId: 0, levelName: 'Level 1'},
    {levelId: 1, levelName: 'Level 2'},
    {levelId: 2, levelName: 'Level 3'},
    {levelId: 3, levelName: 'Level 4'}
]
export const COUNSELLORS = [
    {userId: 0, firstName: 'Udara', lastName: 'Prabath'},
    {userId: 1, firstName: 'Test', lastName: 'User'}
]
export const COURSES = [
    {courseId: 0, courseName: 'Bachelor of Design'},
    {courseId: 1, courseName: 'Bachelor of Science'}
]
export const COURSE_MODULES = [
    {moduleId: 0, moduleName: "IT Management", credits: 2, isCore: true},
    {moduleId: 1, moduleName: "Java Programing", credits: 2, isCore: true},
    {moduleId: 2, moduleName: "Network Security", credits: 2, isCore: true},
    {moduleId: 3, moduleName: "C# Programing", credits: 2, isCore: false},
    {moduleId: 4, moduleName: "Web Development", credits: 2, isCore: false},
    {moduleId: 5, moduleName: "Data Science", credits: 2, isCore: false}
]
export const MARKETING_CODES = [
    {label: "Alfaa Bursary", value: "ALFAA_BURSARY"},
    {label: "Apiit Social media", value: "APIIT_SOCIAL_MEDIA"},
    {label: "Apiitian", value: "APIITIAN"},
    {label: "Apiitizer", value: "APIITIZER"},
    {label: "Branding", value: "BRANDING"},
    {label: "Corporate Promotion", value: "CORPORATE_PROMOTION"},
    {label: "Daily Ft", value: "DAILY_FT"},
    {label: "Dinamina", value: "DINAMINA"},
    {label: "Direct Mailers", value: "DIRECT_MAILERS"},
    {label: "Edex", value: "EDEX"},
    {label: "Email Campaign", value: "EMAIL_CAMPAIGN"},
    {label: "Exhibition", value: "EXHIBITION"},
    {label: "Family", value: "FAMILY"},
    {label: "Hotline", value: "HOTLINE"},
    {label: "Open Day", value: "OPEN_DAY"},
    {label: "Orientation", value: "ORIENTATION"},
    {label: "Press - Daily Mirror", value: "PRESS_DAILY MIRROR"},
    {label: "Press - Veerakesari", value: "PRESS_VEERAKESARI"},
    {label: "Press - Lankadeepa", value: "PRESS_LANKADEEPA"},
    {label: "Press - Daily News", value: "PRESS-DAILY NEWS"},
    {label: "Press - Lakbima", value: "PRESS_LAKBIMA"},
    {label: "Press - Observer", value: "PRESS_OBSERVER"},
    {label: "Press - Sunday Times", value: "PRESS_SUNDAY_TIMES"},
    {label: "Press - Sunday Leader", value: "PRESS_SUNDAYLEADER"},
    {label: "Scholarship", value: "SCHOLARSHIP"},
    {label: "Sis", value: "SIS"},
    {label: "Special Promotion", value: "SPECIAL_PROMOTION"},
    {label: "Apiit Staff Member", value: "APIIT_STAFF_MEMBER"},
    {label: "Tv Program", value: "TV_PROGRAM"},
    {label: "Through Friends", value: "THROUGH_FRIENDS"},
    {label: "Univiser", value: "UNIVISER"},
    {label: "Web Site", value: "WEB_SITE"},
    {label: "Magazine", value: "MAGAZINE"},
    {label: "Other", value: "OTHER"}
]
export const HOBBIES = [
    {label: 'Playing Games -  Indoor', value: 'Playing Games -  Indoor'},
    {label: 'Watching TV Series/ Movies', value: 'Watching TV Series/ Movies'},
    {label: 'App.test.js', value: 'App.test.js'},
    {label: 'Reading Books', value: 'Reading Books'}
]
export const SOCIAL_MEDIA = [
    {label: 'FaceBook', value: 'FaceBook'},
    {label: 'Instagram', value: 'Instagram'},
    {label: 'Twitter', value: 'Twitter'},
    {label: 'Snapchat', value: 'Snapchat'},
    {label: 'Youtube', value: 'Youtube'},
    {label: 'LinkedIn', value: 'LinkedIn'},
    {label: 'TikTok', value: 'TikTok'}
]
export const INCOME = [
    {label: '0 - 50,000', value: '0 - 50,000'},
    {label: '50,000 - 150,000', value: '50,000 - 150,000'},
    {label: '150,000-250,000', value: '150,000-250,000'},
    {label: '250,000 - 500,000', value: '250,000 - 500,000'},
    {label: 'Above 500,000', value: 'Above 500,000'}
]
export const EXPERIENCE = [
    {label: 'Below 1 year', value: 'Below 1 year'},
    {label: '2 - 5 Years', value: '2 - 5 Years'},
    {label: 'Above 5 Years', value: 'Above 5 Years'}
]
export const COURSES_APPLIED = [
    {
        courseId: 0,
        courseName: "BBS - Bachelor of Business Studies",
        electiveModules: [1, 3, 6, 8],
        studyMode: "WEEKEND",
        remark: "Testing remark",
        recommendationLetter: "image",
        studentAgreement: null,
        intakeId: 1,
        intakeCode: '20210101',
        status: "PENDING"
    },
    {
        courseId: 1,
        courseName: "BSc. Computer Science",
        electiveModules: [1, 3, 6, 8],
        studyMode: "WEEKEND",
        remark: "Testing remark",
        recommendationLetter: "image",
        studentAgreement: null,
        intakeId: 5,
        intakeCode: '20210101',
        status: "PENDING"
    }
]
export const COURSE_APPLIED_REMARKS = [
    {
        remarkId: 0,
        recommendation: "Recommended",
        courseName: "Integrated Law Course- BA + LL.B",
        remark: "Et laoreet sit molestie arcu in in placerat tempus in.",
        remarkDate: "2022/12/12",
        feedback: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod ipsum at.",
        userName: "Dr.Nihal Wimalasooriya",
        UserRole: "Head of the Department"
    },
    {
        remarkId: 1,
        recommendation: "Rejected",
        courseName: "BBS- Bachelor of Business Studies",
        remark: "Et laoreet sit molestie arcu in in placerat tempus in.",
        remarkDate: "2022/12/12",
        feedback: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod ipsum at.",
        userName: "Dr.Nihal Wimalasooriya",
        UserRole: "Head of the Department"
    }
]
export const OFFER_LETTERS = [
    // {
    //     label: 'Pending A/L',
    //     value: 5,
    //     conditional: true
    // },
    // {
    //     label: 'Pending O/L',
    //     value: 6,
    //     conditional: true
    // },
    // {
    //     label: 'Students who join 2nd year/3rd year with exemtions',
    //     value: 12,
    //     conditional: true
    // },
    // {
    //     label: 'Students who have sat for the A/L examination but with pending results who wishes to complete degree in 2 years',
    //     value: 14,
    //     conditional: true
    // },
    //
    // {
    //     label: 'Students who meets the entry Qualification for PG',
    //     value: 7,
    //     conditional: false
    // },
    // {
    //     label: 'Students who have received O/L & A/L results and met the entry qualification',
    //     value: 8,
    //     conditional: false
    // },
    // {
    //     label: 'Students who have received O/L results and met the entry qualification',
    //     value: 9,
    //     conditional: false
    // },
    // {
    //     label: 'Students who have sat for the A/L examination meets entry requirement and who wishes to complete degree in 2 years',
    //     value: 17,
    //     conditional: false
    // },
    // {
    //     label: 'Students who have received O/L results and met the entry qualification',
    //     value: 20,
    //     conditional: false
    // },
    // {
    //     label: 'Pending O/L',
    //     value: 21,
    //     conditional: false
    // }
    {
        label: 'NCUK - For students with PENDING OL',
        value: 19,
        conditional: true
    },
    {
        label: 'APIIT Foundation - For students with PENDING OL',
        value: 5,
        conditional: true
    },
    {
        label: 'IT Degree - For students with PENDING AL/ Other Qualification',
        value: 9,
        conditional: true
    },
    {
        label: 'Business Degree - For students with PENDING AL/ Other Qualification',
        value: 1,
        conditional: true
    },
    {
        label: 'LLB Degree - For students with PENDING AL/ Other Qualification',
        value: 13,
        conditional: true
    },

    {
        label: 'NCUK - For students with OL RESULTS',
        value: 21,
        conditional: false
    },
    {
        label: 'APIIT Foundation - For students with OL RESULTS',
        value: 7,
        conditional: false
    },
    {
        label: 'IT Degree - For students with AL RESULTS',
        value: 11,
        conditional: false
    },
    {
        label: 'Business Degree - For students with AL RESULTS',
        value: 3,
        conditional: false
    },
    {
        label: 'LLB Degree - For students with AL RESULTS',
        value: 15,
        conditional: false
    },
    {
        label: 'Masters - For all students',
        value: 17,
        conditional: false
    }
]
export const QUALIFICATIONS = [
    {
        qualificationId: 0,
        qualification: "G.C.E Ordinary Level (Local)",
        indexNo: "120280202902",
        level: "MATHS",
        year: "2022",
        status: "APPROVED",
        school: "Royal College",
        comments: "some comment",
        results: [
            {
                subjectName: "Combined Mathematics",
                result: "A"
            },
            {
                subjectName: "Physics",
                result: "A"
            },
            {
                subjectName: "Chemistry",
                result: "A"
            }
        ],
        transcript: "image",
        otherAttachment: "image"
    },
    {
        qualificationId: 1,
        qualification: "G.C.E Advance Level (Local)",
        indexNo: "120280202902",
        level: "MATHS",
        year: "2022",
        status: "PENDING",
        school: "Royal College",
        comments: "some comment",
        results: [
            {
                subjectName: "Combined Mathematics",
                result: "A"
            },
            {
                subjectName: "Physics",
                result: "A"
            },
            {
                subjectName: "Chemistry",
                result: "A"
            }
        ],
        transcript: "image",
        otherAttachment: "image"
    }
]
export const QUALIFICATION_LIST = [
    {label: 'G.C.E O/L', value: 'G.C.E O/L'},
    {label: 'G.C.E A/L', value: 'G.C.E A/L'},
    {label: 'GCSE O/L', value: 'GCSE O/L'},
    {label: 'GCSE A/L', value: 'GCSE A/L'},
    {label: 'Foundation', value: 'Foundation'},
    {label: 'L4 - 1', value: 'L4 - 1'},
    {label: 'L5 - 2', value: 'L5 - 2'},
    {label: 'L6 - 3', value: 'L6 - 3'},
    {label: 'L7 - PG Diploma', value: 'L7 - PG Diploma'},
    {label: 'HND', value: 'HND'},
    {label: 'ACCA', value: 'ACCA'},
    {label: 'CIMA', value: 'CIMA'},
    {label: 'CIM', value: 'CIM'},
    {label: 'AAT', value: 'AAT'},
    {label: 'Attorneys', value: 'Attorneys'}
]
export const PAYMENT_PLAN_TYPES = [
    {typeId: 0, name: 'Full Payment'},
    {typeId: 1, name: 'Yearly Payment'},
    {typeId: 2, name: 'Semester Payment'},
    {typeId: 3, name: 'Monthly Payment'}
]
export const CUSTOM_DISCOUNT_TYPES = [
    {label: 'Not Applied', value: 'NONE', color: 'light-secondary'},
    {label: 'Pending FE Approval', value: 'PENDING_FE_APPROVAL', color: 'light-warning'},
    {label: 'FE Rejected', value: 'FE_REJECTED', color: 'light-danger'},

    {label: 'FE Approved', value: 'FE_APPROVED', color: 'light-success'},
    {label: 'Pending FM Approval', value: "SEND_TO_FM", color: 'light-warning'},
    {label: 'Pending HOF Approval', value: "SEND_TO_HOF", color: 'light-warning'},

    {label: 'FE Rejected', value: "FE_REJECTED", color: 'light-danger'},
    {label: 'FM Rejected', value: "FM_REJECTED", color: 'light-danger'},
    {label: 'HOF Rejected', value: "HOF_REJECTED", color: 'light-danger'},
    {label: 'FE Approved', value: "FE_APPROVED", color: 'light-success'},
    {label: 'FM Approved', value: "FM_APPROVED", color: 'light-success'},
    {label: 'HOF Approved', value: "HOF_APPROVED", color: 'light-success'}
]

export const DISCOUNT_TYPES = [
    {typeId: 0, name: 'Discount type 1'},
    {typeId: 1, name: 'Discount type 2'}
]
export const PAYMENT_PLAN = [
    {description: 'Registration Fee 2021 ', feeType: 'Registration Fee', dueDate: '01/01/2021', amount: '50,000.00'},
    {
        description: '50% off early bird discount',
        feeType: 'Early Bird Discount',
        dueDate: '01/01/2021',
        amount: '50,000.00'
    },
    {description: 'Library Fee 2021', feeType: 'Library Fee', dueDate: '01/01/2021', amount: '50,000.00'}
]
export const FOLLOW_UPS = [
    {
        followUpId: 0,
        date: '01/01/2021',
        counsellorId: 1,
        counsellorName: 'Rohana De Mel',
        intakeId: 1,
        intakeCode: '2021 FT Oct IT',
        nextFollowUp: '02/02/2021',
        status: 'FUTURE_INTAKE',
        time: '02.30 PM',
        remark: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod ipsum at.'
    }
]
export const FOLLOW_UP_STATUS = ['FOLLOW_UP', 'NOT_INTERESTED', 'FUTURE_INTAKE']
export const INQUIRY_PROSPECT_ACTIONS = ['ADD', 'VIEW', 'EDIT']
export const GENDER = ['MALE', 'FEMALE']
export const MARITAL_STATUS = ['SINGLE', 'MARRIED']
export const LOCALITY = ['LOCAL', 'FOREIGN']
export const FILE_TYPES = ['NIC', 'RESUME', 'PROFILE_IMAGE', 'SERVICE_LETTER', "COURSE_RECOMMENDATION_LETTER", "COURSE_STUDENT_AGREEMENT", "QUALIFICATION_TRANSCRIPT", "QUALIFICATION_DOCUMENT", "COURSE_DETAILS_SHARE_DOCUMENTS", "SPECIAL_APPROVE_SEND_TO_AA", "INQ_EXEMPTION"]
export const API_RESPONSE_STATUS = [0, 1]
export const COURSE_DETAILS_SHARE = ['REGISTRATION_LINK', 'COURSE_DETAILS', 'REG_AND_COURSE']
export const GUR_ADDRESS = ['STUDENT_RESIDENTIAL', 'STUDENT_PERMANENT', 'OTHER']
export const CSV_HEADER = [
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Inquiry Status", key: "inquiryStatus"},
    {label: "Intake Id", key: "intakeId"},
    {label: "Inquiry Type", key: "inquiryType"},
    {label: "Remark", key: "remark"},
    {label: "Organization Email", key: "orgEmail"},
    {label: "Counsellor Id", key: "userId"}
]

export const ALL_INQUIRIES_CSV_HEADER = [
    {label: "Inq No.", key: "inquiryId"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Inquiry Type", key: "inquiryType"},
    {label: "Inquiry Date", key: "inquiryDate"},
    {label: "Counsellor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    {label: "Marketing Code", key: "marketingCode"},
    {label: "Intake Code", key: "intakeCode"},
    {label: "Status", key: "status"}
]

export const MY_INQUIRIES_CSV_HEADER = [
    {label: "InquiryNumber", key: "inquiryNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Inquiry Type", key: "inquiryType"},
    {label: "Inquiry Date", key: "inquiryDate"},
    {label: "Counsellor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    {label: "Marketing Code", key: "marketingCode"},
    {label: "Intake Code", key: "intakeCode"}
    // {label: "Status", key: "status"}
]

export const MY_INQUIRIES_TEMPLATE_CSV_HEADER = [
    {label: "studentName", key: "studentName"},
    {label: "studentMobile", key: "studentMobile"},
    {label: "inquiryStatus", key: "inquiryStatus"},
    {label: "intakeId", key: "intakeId"},
    {label: "courseId", key: "courseId"},
    {label: "inquiryType", key: "inquiryType"},
    {label: "remark", key: "remark"},
    {label: "orgEmail", key: "orgEmail"},
    {label: "counselorId", key: "counselorId"}
]
export const UNASSIGNED_INQUIRIES_TEMPLATE_CSV_HEADER = [
    {label: "studentName", key: "studentName"},
    {label: "studentMobile", key: "studentMobile"},
    {label: "studentEmail", key: "studentEmail"},
    {label: "remark", key: "remark"}
    // {label: "courseCode", key: "courseCode"}
]
export const MARK_ATTENDANCE_TEMPLATE_CSV_HEADER = [
    {label: "cbNumber", key: "cbNumber"},
    {label: "studentName", key: "studentName"},
    {label: "remark", key: "remark"},
    {label: "batchCode", key: "batchCode"},
    {label: "present", key: "present"},
    {label: "absent", key: "absent"},
    {label: "late", key: "late"}
]
export const MARK_ATTENDANCE_EXPORT_CSV_HEADER = [
    {label: "cbNumber", key: "cbNumber"},
    {label: "studentName", key: "studentName"},
    {label: "remark", key: "remark"},
    {label: "batchCode", key: "batchCode"},
    {label: "presence", key: "presence"}
]
export const MARK_ATTENDANCE_SUMMARY_EXPORT_CSV_HEADER = [
    {label: "cbNumber", key: "cbNumber"},
    {label: "studentName", key: "studentName"},
    {label: "batch", key: "batch"},
    {label: "course", key: "course"},
    {label: "courseCode", key: "courseCode"},
    {label: "avg", key: "avg"}
]

export const STUDENT_ASSESSMENTS_EXPORT_CSV_HEADERS = [
    {label: "Level Name", key: "levelName"},
    {label: "Semester", key: "semesterName"},
    {label: "Module Name", key: "moduleName"},
    {label: "Assessment", key: "assessmentName"},
    {label: "Assessment Type", key: "assessmentType"},
    {label: "Submission Date", key: "submissionDate"},
    {label: "Weightage", key: "weightage"}
]

export const ALL_FOLLOWUP_CSV_HEADER = [
    {label: "InquiryNumber", key: "inquiryNumber"},
    {label: "Remark", key: "remark"},
    {label: "StudentName", key: "studentName"},
    {label: "StudentMobile", key: "studentMobile"},
    {label: "StudentEmail", key: "studentEmail"},
    {label: "InquiryType", key: "inquiryType"},
    {label: "NextFollowupDate", key: "nextFollowupDate"},
    {label: "CounselorName", key: "counselorName"},
    {label: "CourseName", key: "courseName"},
    {label: "AppointmentDate", key: "appointmentDate"},
    {label: "LastFollowupDate", key: "lastFollowupDate"}
]

export const ENROLLMENT_CSV_HEADER = [
    {label: "inquiryNumber", key: "inquiryNumber"},
    {label: "studentName", key: "studentName"},
    {label: "studentMobile", key: "studentMobile"},
    {label: "studentEmail", key: "studentEmail"},
    {label: "counselorName", key: "counselorName"},
    {label: "courseName", key: "courseName"},
    {label: "apiitReferenceNumber", key: "apiitReferenceNumber"},
    {label: "requestedDate", key: "requestedDate"}
]

export const REGISTRATION_CSV_HEADER = [
    {label: "cbNumber", key: "cbNumber"},
    {label: "studentName", key: "studentName"},
    {label: "studentContact", key: "studentContact"},
    {label: "studentEmail", key: "studentEmail"},
    {label: "registeredDate", key: "registeredDate"},
    {label: "counselorName", key: "counselorName"},
    {label: "intake", key: "intake"},
    {label: "paymentPlan", key: "paymentPlan"},
    {label: "paymentCollectedBy", key: "paymentCollectedBy"}
]

export const SUBMITTED_APPLICATION_CSV_HEADER = [
    {label: "InquiryId", key: "inquiryId"},
    {label: "StudentName", key: "studentName"},
    {label: "StudentMobile", key: "studentMobile"},
    {label: "StudentEmail", key: "studentEmail"},
    {label: "InquiryType", key: "inquiryType"},
    {label: "RequestedDate", key: "requestedDate"},
    {label: "CounselorName", key: "counselorName"},
    {label: "CourseName", key: "courseName"}
]

export const NOT_INTERESTED_CSV_HEADER = [
    {label: "Inquiry Number", key: "inquiryNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Inquiry Type", key: "inquiryType"},
    {label: "Last Followup Date", key: "lastFollowupDate"},
    {label: "Counselor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    {label: "Remark", key: "followUpRemark"}
]

export const PENDING_PAYMENT_CSV_HEADER = [
    {label: "Inquiry Id", key: "inquiryId"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Counselor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    {label: "Registration Due Date", key: "requestedDate"},
    // {label: "apiitReferenceNumber", key: "apiitReferenceNumber"},
    {label: "Payment Plan", key: "paymentPlan"},
    {label: "Remark", key: "remark"}
]

export const FUTURE_INTAKE_CSV_HEADER = [
    {label: "Inquiry Number", key: "inquiryNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Intake", key: "intake"},
    {label: "Inquiry Type", key: "inquiryType"},
    {label: "Counselor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    {label: "Last Followup Date", key: "lastFollowupDate"},
    {label: "Remark", key: "followUpRemark"}
]

export const HOA_APPROVAL_CSV_HEADER = [
    {label: "inquiryId", key: "inquiryId"},
    {label: "studentName", key: "studentName"},
    {label: "studentMobile", key: "studentMobile"},
    {label: "studentEmail", key: "studentEmail"},
    {label: "counselorName", key: "counselorName"},
    {label: "courseName", key: "courseName"},
    {label: "approvedDate", key: "approvedDate"},
    {label: "remark", key: "remark"},
    {label: "subStatus", key: "subStatus"},
    {label: "apiitReferenceNumber", key: "apiitReferenceNumber"},
    {label: "approvedBy", key: "approvedBy"}
]

export const BANK_TRANSFER_CSV_HEADER = [
    {label: "CB Number", key: "cbNo"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Request Date", key: "requestDate"},
    {label: "Status", key: "status"},
    {label: "Counsellor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    // {label: "Discounts", key: "discounts"},
    {label: "Intake Code", key: "intakeCode"},
    {label: "Deposit Date", key: "depositDate"},
    {label: "Fee Type", key: "feeType"},
    {label: "Amount", key: "amount"}
]

export const COLOR_STATUS = ['success', 'danger', 'warning', 'info', 'dark', 'primary', 'secondary', 'purple']
export const COURSE_APPLIED_STATUS = ["PENDING", "ENROLLED", "REJECTED", "NONE", "RECOMMENDED", "DECLINED"]
export const HOA_APPROVALS_STATUS = ["PENDING", "APPROVED", "REJECTED"]
export const SPECIAL_APPROVAL_STATUS = ["PENDING", "APPROVED", "REJECTED"]
export const HOS_SPECIAL_APPROVAL_STATUS = ["SEND_TO_HOS", "HOS_RECOMMENDED", "HOS_REJECTED", "AA_RECOMMENDED", "AA_REJECTED", "FM_RECOMMENDED", "FM_REJECTED", "HOF_RECOMMENDED", "HOF_REJECTED", "SEND_TO_AA", "SEND_TO_HOF", "SEND_TO_FM"]
export const SPECIAL_APPROVAL_CURRENT_STATUS = ["AA_APPROVED", "AA_REJECTED", "SEND_TO_AA"]
export const COURSE_APPLIED_MODAL_ACTION = ["ADD", "EDIT"]
export const SPECIAL_APPROVAL_MODAL_ACTION = ["ADD", "VIEW", "EDIT"]
export const QUALIFICATION_APPLIED_MODAL_ACTION = ["ADD", "EDIT"]
export const STUDY_MOOD = ["WEEK_DAYS", "WEEKEND"]
export const TARGET_TYPE = ["GROUP", "INDIVIDUAL"]
export const OFFER_TYPE = ["CONDITIONAL", "UNCONDITIONAL"]
export const CURRENCY = ["LKR", "USD"]
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_2 = 'YYYY/MM/DD'
export const DATE_FORMAT_H = 'YYYY-MM-DD HH-MM'
export const DATE_FORMAT_H2 = 'YYYY-MM-DD HH:MM'
export const DATE_FORMAT_TABLE = 'DD/MM/YYYY'
export const DATE_RANGE_FORMAT = 'd/m/Y'
export const YEAR_MONTH_FORMAT = 'Y-M-d'
export const CSV_EXTENSION_FORMAT = 'application/vnd.ms-excel'
export const ROLES = [
    {label: 'IT Manager', value: 'IT_MANAGER', department: 1},
    {label: 'IT Executive', value: 'IT_EXECUTIVE', department: 1},
    {label: 'Head of School', value: 'HEAD_OF_SCHOOL', department: 2},
    {label: 'Lecturer ', value: 'LECTURER', department: 2},
    {label: 'Head of Academic Admin', value: 'HEAD_OF_ACADEMIC_ADMIN', department: 2},
    {label: 'Manager Academic Admin', value: 'MANAGER_ACADEMIC_ADMIN', department: 2},
    {label: 'Academic Administrator', value: 'ACADEMIC_ADMINISTRATOR', department: 2},
    {label: 'Head of Counselor', value: 'HEAD_OF_COUNSELLOR', department: 3},
    {label: 'Counselor', value: 'COUNSELLOR', department: 3},
    {label: 'Receptionist ', value: 'RECEPTIONIST', department: 3},
    {label: 'Head of Finance', value: 'HEAD_OF_FINANCE', department: 4},
    {label: 'Finance Manager', value: 'FINANCE_MANAGER', department: 4},
    {label: 'Finance Assistant Manager', value: 'FINANCE_ASSISTANT_MANAGER', department: 4},
    {label: 'Finance Executive', value: 'FINANCE_EXECUTIVE', department: 4},
    {label: 'Student Life Manager', value: 'STUDENT_LIFE_MANAGER', department: 5},
    {label: 'Student Support Services Manager', value: 'STUDENT_SUPPORT_SERVICES_MANAGER', department: 5},
    {label: 'Director', value: 'DIRECTOR', department: 6}
    // {label: 'Student', value: 'STUDENT'},
    // {label: 'Internship Manager', value: 'INTERNSHIP_MANAGER', department:3},
    // {label: 'Parents', value: 'PARENTS'},
    // {label: 'Marketing Manager', value: 'MARKETING_MANAGER', department:4}
    // {label: 'Librarian', value: 'LIBRARIAN', department:3}
]
export const FOLLOWUP_TYPES = {
    pendingPayment: "PENDING_PAYMENT",
    applicationSubmitted: "APPLICATION_SUBMITTED",
    futureInquiry: "FUTURE_INQUIRY",
    notInterested: "NOT_INTERESTED",
    hoaApproval: "HOA_APPROVAL",
    hosApproval: "HOS_APPROVAL"
}
export const HOS_STATUS = [
    {label: 'All', value: 'ALL'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Shared to AA', value: 'SHARED_TO_AA'},
    {label: 'Pending by AA', value: 'PENDING_BY_AA'},
    {label: 'Pending by FM', value: 'PENDING_BY_FM'}
]
export const FM_EXCEMPTION_STATUS = [
    {label: 'All', value: 'ALL'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Rejected', value: 'REJECTED'}
]

export const FM_EXCEMPTION_NEW_STATUS = [
    // {label: 'All', value: 'ALL'},
    // {label: 'Pending', value: 'PENDING'},
    // {label: 'Recommended', value: 'RECOMMENDED'},
    // {label: 'Rejected', value: 'REJECTED'}
    {
        value: null,
        label: 'All'
    },
    {
        value: "SEND_TO_FM",
        label: "Send to FM"
    },
    {
        value: "SEND_TO_HOF",
        label: "Send to HOF"
    },
    // {
    //     value: "SEND_TO_HOS",
    //     label: 'Send to HOS'
    // }, {
    //     value: "HOS_RECOMMENDED",
    //     label: "HOS Recommended"
    // }, {
    //     value: "HOS_REJECTED",
    //     label: "HOS Rejected"
    // }, {
    //     value: "AA_RECOMMENDED",
    //     label: "AA Recommended"
    // },
    // {
    //     value: "AA_REJECTED",
    //     label: "AA Rejected"
    // },
    // {
    //     value: "FM_RECOMMENDED",
    //     label: "FM Recommended"
    // },
    {
        value: "FM_REJECTED",
        label: "FM Rejected"
    }, {
        value: "HOF_RECOMMENDED",
        label: "HOF Recommended"
    }, {
        value: "HOF_REJECTED",
        label: "HOF Rejected"
    }
    // {
    //     value: "SEND_TO_AA",
    //     label: "Send to AA"
    // },
]

// export const PAYMENT_INTERVALS = [
//     {label: 'Monthly - 30 days ', value: '1-M-ANY'},
//     {label: '2 Semester - 3 months', value: '3-M-2'},
//     {label: '2 Semester - 4 months', value: '4-M-2'},
//     {label: '3 Semester - 3 months ', value: '3-M-3'},
//     {label: '4 Semester - 4 months', value: '4-M-4'},
//     {label: 'Annual - one time payment ', value: '12-M-ANY'}
// ]

export const FEE_TYPE = [
    {label: 'Registration Fee', value: 'REGISTRATION_FEE'},
    {label: 'Library Fee', value: 'LIBRARY_FEE'},
    {label: 'SU Fee', value: 'SU_FEE'},
    {label: 'Course Fee', value: 'COURSE_FEE'}
]

export const DISCOUNT_TYPE = [
    {label: 'Percentage', value: 'PERCENTAGE'},
    {label: 'Amount(LKR)', value: 'FIXED'},
    {label: 'Amount(USD)', value: 'FIXED'}
]

export const PAYMENT_METHOD = [
    {label: 'Online', value: 'ONLINE'},
    {label: 'Cash', value: 'CASH'},
    {label: 'Bank Deposit', value: 'BANK'}
]

export const ALL_PAYMENT_METHOD = [
    {label: 'Online', value: 'ONLINE'},
    {label: 'Cash', value: 'CASH'},
    {label: 'Bank Deposit', value: 'BANK'},
    {label: 'Deposit', value: 'DEPOSIT'},
    {label: 'Cheque', value: 'CHEQUE'},
    {label: 'Card', value: 'CARD'}
]

export const PAYMENT_TYPE = [
    {label: 'Payment', value: 'PAYMENT'},
    {label: 'Refund', value: 'REFUND'},
    {label: 'Transfer', value: 'TRANSFER'}
]

export const TABLE_STATUS = ["ACTIVE", "INACTIVE"]
export const FEE_SCHEME_STATUS = [
    {value: null, label: 'All'},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Inactive', value: 'INACTIVE'}
]
export const SUBMITTED_APPLICATION_STATUS = [
    {value: null, label: 'All'},
    {label: 'Offer Letter Shared', value: 'OFFER_LETTER_SHARED'},
    {label: 'Registration Link Shared', value: 'REGISTRATION_LINK_SHARED'},
    {label: 'Application Submitted', value: 'APPLICATION_SUBMITTED'},
    {label: 'Special Approved', value: 'SPECIAL_APPROVED'},
    {label: 'Pending Payment', value: 'PENDING_PAYMENT'},
    {label: 'Pending Enrollment', value: 'PENDING_ENROLLMENT'},
    {label: 'Course Detail Shared', value: 'COURSE_DETAIL_SHARED'}
]


export const SCHEME_ENROLLED_TYPE = [
    {label: 'Student Enrolled', value: 'STUDENT_ENROLLED'},
    {label: 'No Payment Scheme Assigned', value: 'NO_PAYMENT_SCHEME_ASSIGNED'},
    {label: 'Payment Scheme Assigned', value: 'PAYMENT_SCHEME_ASSIGNED'}
]

export const COURSE_TYPES = [
    {label: 'Full Time', value: 'FULL_TIME'},
    {label: 'Part Time', value: 'PART_TIME'},
    {label: 'Online', value: 'ONLINE'},
    {label: 'Physical', value: 'PHYSICAL'}
]

export const GRADING_SCHEME_STATUS = [
    {label: 'Pass', value: 'PASS', key: 0},
    {label: 'Fail', value: 'FAIL', key: 1}
]

export const STUDY_MODES = [
    {label: 'Full Time', value: 'FULL_TIME'},
    {label: 'Part Time', value: 'PART_TIME'},
    {label: 'Online', value: 'ONLINE'},
    {label: 'Physical', value: 'PHYSICAL'}
]

export const OVERALL_MARK_CALCULATION = [
    {label: 'Grading point average (Course Grading)', value: 'GRADING_POINT_AVERAGE'},
    {label: 'Total grade point (Module Grading)', value: 'TOTAL_GRADING_POINT'}
]

export const GPA_TYPE = [
    {label: 'Calculate ', value: 'CALCULATE'},
    {label: 'Not calculate ', value: 'NOT_CALCULATE'}
]

export const MODULE_TYPE = [
    {label: 'Core ', value: 'CORE'},
    {label: 'Elective ', value: 'ELECTIVE'}
]

export const MODULE_CATEGORY = [
    {label: 'Normal ', value: 'NORMAL'},
    {label: 'Project', value: 'PROJECT'},
    {label: 'Multi Semester', value: 'MULTI_SEMESTER'}

]

export const MODULE_CSV_HEADER = [
    {label: "Module Code", key: "code"},
    {label: "Module Name", key: "name"},
    {label: "Description", key: "description"},
    {label: "Category", key: "category"},
    {label: "Type", key: "type"},
    {label: "Credits", key: "credits"},
    {label: "GPA Type", key: "gpa"},
    {label: "Assessment Scheme", key: "scheme"}
]

export const COURSE_CSV_HEADER = [
    {label: "Course Code", key: "code"},
    {label: "Course Name", key: "name"},
    {label: "Description", key: "description"},
    {label: "Qualification", key: "qualification"},
    {label: "Specialization", key: "specialization"},
    {label: "type", key: "type"},
    {label: "School", key: "school"},
    {label: "Provide", key: "provide"},
    {label: "Program Code", key: "program"},
    {label: "Level", key: "level"},
    {label: "Center", key: "center"},
    {label: "Credits", key: "credits"}
]

export const MARKINGS_CSV_HEADER = [
    {label: "Batch", key: "batchName"},
    {label: "Module", key: "moduleName"},
    {label: "Course", key: "courseName"},
    {label: "Level", key: "levelName"},
    {label: "Semester", key: "semesterName"},
    {label: "Student", key: "studentName"},
    {label: "CB No", key: "cbNo"},
    {label: "Diagnostic Assessment", key: "diagnosticAssessment"},
    {label: "Formative Assessment", key: "formativeAssessment"},
    {label: "Summary Assessment", key: "summativeAssessment"},
    {label: "Average", key: "average"},
    {label: "Exam Status", key: "examStatus"}
]

export const STUDENTS_MARKINGS_CSV_HEADER = [
    {label: "Student", key: "studentName"},
    {label: "CB No", key: "cbNo"},
    {label: "Diagnostic Type", key: "diagnosticType"},
    {label: "Diagnostic Assessment", key: "diagnosticAssessment"},
    {label: "Diagnostic Grade", key: "diagnosticGrade"},
    {label: "Formative Type", key: "formativeType"},
    {label: "Formative Assessment", key: "formativeAssessment"},
    {label: "Formative Grade", key: "formativeGrade"},
    {label: "Summary Type", key: "summativeType"},
    {label: "Summary Assessment", key: "summativeAssessment"},
    {label: "Summary Grade", key: "summativeGrade"},
    {label: "Average", key: "average"},
    {label: "Average Grade", key: "averageGrade"},
    {label: "Exam Status", key: "examStatus"}

]

export const FILTER_TYPES = {
    input: 'INPUT',
    dropDown: 'DROP_DOWN',
    datePicker: 'DATE_PICKER',
    rangePicker: 'RANGE_PICKER',
    number: 'NUMBER',
    timeRangePicker: 'TIME_RANGE_PICKER'
}

export const ENROLLMENT_REQUESTS_CSV_HEADER = [
    {label: "Inquiry No", key: "inquiryNo"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Request Date", key: "requestDate"},
    {label: "Status", key: "status"},
    {label: "Counsellor Name", key: "counselorName"},
    {label: "Course Name", key: "courseName"},
    // {label: "Discounts", key: "discounts"},
    {label: "Intake Code", key: "intakeCode"},
    {label: "Deposit Date", key: "depositDate"},
    {label: "Fee Type", key: "feeType"},
    {label: "Amount", key: "amount"}
]

export const CLASS_SETUP_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Student No.", key: "studentId"},
    {label: "Student Name", key: "studentName"},
    {label: "CB Number", key: "cbNumber"},
    {label: "Class Name", key: "className"},
    {label: "Class Code", key: "classCode"},
    {label: "Course Name", key: "courseName"},
    {label: "Batch Code", key: "batchCode"}
]
export const UNASSIGNED_INQUIRIES_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Inq No.", key: "inquiryId"},
    {label: "Inquiry Date", key: "requestedDate"},
    {label: "Student Name", key: "studentName"},
    {label: "Student Mobile", key: "studentMobile"},
    {label: "Student Email", key: "studentEmail"},
    {label: "Remark", key: "remark"},
    {label: "Course Name", key: "courseName"}
]
export const UPCOMING_CLASSES_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Class Date", key: "actualDate"},
    {label: "Class Time", key: "classTime"},
    {label: "Lecturer Name", key: "lectureName"},
    {label: "Venue", key: "venueName"},
    {label: "Module Name", key: "moduleName"},
    {label: "Module Code", key: "moduleCode"},
    {label: "Class Name", key: "className"},
    {label: "Class Slot Name", key: "slotName"}
]
export const EXAM_SCHEDULE_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Exam Date", key: "examDate"},
    {label: "Module", key: "moduleName"},
    {label: "Lecturer Name", key: "lecturer"},
    {label: "Time", key: "time"},
    {label: "Total No of Students", key: "noOfStudents"}
]
export const COURSE_TRANSFER_REQUESTS_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Student Name", key: "studentName"},
    {label: "CB Number", key: "cbNumber"},
    {label: "Current Course", key: "currentCourse"},
    {label: "Requested Course", key: "requestedCourse"},
    {label: "Status", key: "status"},
    {label: "Branch", key: "branchName"},
    {label: "Level", key: "levelName"},
    {label: "Intake", key: "intakeCode"},
    {label: "Payment Plan", key: "paymentPlan"},
    {label: "Student Remark", key: "studentRemark"}
]
export const ASSESSMENT_TIME_TABLE_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "School", key: "schoolName"},
    {label: "Course Name", key: "courseName"},
    {label: "Batch", key: "batchCode"}
]
export const EXAM_STUDENT_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "CB Number", key: "cbNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Class Name", key: "className"},
    {label: "Batch", key: "batchCode"},
    {label: "Attempt", key: "attemptName"}
]
export const CUSTOM_DISCOUNT_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Inq No.", key: "inquiryNumber"},
    {label: "Requested Date", key: "requestDate"},
    {label: "Student Name", key: "studentName"},
    {label: "Course Name", key: "courseName"},
    {label: "Status", key: "status"},
    {label: "Intake", key: "intakeCode"},
    {label: "Payment Plan", key: "paymentPlanName"},
    {label: "Counsellor", key: "counsellorRemark"},
    {label: "FE Remark", key: "feRemark"},
    {label: "FM Remark", key: "fmRemark"}
]

export const DAILY_TRANSACTION_CSV_HEADER = [
    {label: "Receipt No", key: "receiptId"},
    {label: "Student Name", key: "studentName"},
    {label: "StudentId", key: "studentId"},
    {label: "Transaction Type", key: "transactionType"},
    {label: "Amount", key: "amount"},
    {label: "Date", key: "date"},
    {label: "Counsellor", key: "counsellorName"},
    {label: "Course", key: "courseName"},
    {label: "Batch", key: "batchCode"},
    {label: "Email", key: "studentEmail"},
    {label: "Contact No", key: "studentNumber"},
    {label: "Intake", key: "intakeId"},
    {label: "Payment Type", key: "paymentType"},
    {label: "Payment Method", key: "paymentMethod"},
    {label: "Discount", key: "discount"},
    {label: "Deposit Date", key: "depositedDate"},
    {label: "Refund Type", key: "refundType"},
    {label: "Refund Method", key: "refundMethod"},
    // {label: "Transfer Type", key: "transferType"},
    // {label: "Transfer Method", key: "transferMethod"},
    {label: "Transfer In", key: "transferIn"},
    {label: "Transfer Out", key: "transferOut"},
    {label: "Description", key: "description"}
]

export const COLLECT_PAYMENT_CSV_HEADER = [
    {label: "CB NO.", key: "cbNo"},
    {label: "Student Name", key: "studentName"},
    {label: "Contact", key: "contact"},
    {label: "Email", key: "email"},
    {label: "Last Payment", key: "lastPayment"},
    {label: "Counsellor", key: "counselor"},
    {label: "Course", key: "course"},
    {label: "Total Amount", key: "totalAmount"},
    {label: "Batch", key: "batch"},
    {label: "Payment Plan", key: "paymentPlan"},
    {label: "Outstanding", key: "outstanding"},
    {label: "Intake", key: "intake"},
    {label: "Payment Due", key: "paymentDue"}
]

export const PENDING_COLLECTION_CSV_HEADER = [
    {label: "CB No.", key: "cbNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "StudentId", key: "studentId"},
    {label: "Contact No", key: "studentMobile"},
    {label: "Email", key: "studentEmail"},
    {label: "Due Date", key: "dueDate"},
    {label: "Amount", key: "amount"},
    {label: "Late Fee", key: "lateFee"},
    {label: "Course", key: "courseName"},
    {label: "Batch", key: "batchCode"},
    {label: "Last Payment Date", key: "lastPaymentDate"},
    {label: "Last Payment Amount", key: "lastPaymentAmount"}
]

export const INVOICE_PAYMENT_METHOD = [
    {label: 'Deposit', value: 'DEPOSIT'},
    {label: 'Cheque', value: 'CHEQUE'},
    {label: 'Cash', value: 'CASH'},
    {label: 'Card', value: 'CARD'},
    {label: 'Frimi', value: 'FRIMI'},
    {label: 'MyFees', value: 'MYFEES'},
    {label: 'Online Transfer', value: 'ONLINE_TRANSFER'}
]

export const APPROVE_PAYMENT_VIEW = {
    enrollment: 'ENROLLMENT_REQUEST',
    bankTransfer: 'BANK_TRANSFER',
    collectPayment: 'COLLECT_PAYMENT',
    //this was added to handle the change payment plan btn in smp
    studentProfile: 'STUDENT_PROFILE'
}

export const FILTERS = {
    name: undefined,
    course: undefined,
    dateRange: undefined,
    appointmentDate: undefined,
    lastFollowupDate: undefined,
    hos: undefined,
    status: undefined,
    counsellor: undefined,
    intake: undefined,
    inquiryType: undefined,
    marketingCode: undefined,
    inquiryStatus: undefined,
    counselor: undefined,
    feeNo: undefined,
    feeName: undefined,
    batch: null,
    cb: '',
    collected: null,
    method: null,
    nic: '',
    receipt: '',
    type: null,
    cbNumber: undefined,
    studentName: undefined,
    nicPassport: undefined,
    startDate: undefined,
    endDate: undefined,
    courseId: undefined,
    counselorId: undefined,
    employeeNo: '',
    department: undefined,
    role: undefined,
    school: undefined,
    defaultStatue: {value: 'PENDING', label: 'Pending'},
    subStatus: null,
    classCode: undefined,
    contact: '',
    module: undefined,
    attempt: undefined,
    level: undefined,
    class: undefined,
    inquiryNo: '',
    lecturerId: undefined,
    lecturer: undefined,
    venue: undefined,
    timeRange: undefined,
    semester: undefined
}

export const INVOICE_TYPE = {
    inquiry: 'inquiry',
    student: 'student'
}

export const academicTableFooter = [
    {
        class: 'class-week',
        label: 'Class Week',
        iconText: '1'
    },
    {
        class: 'exam-week',
        label: 'Exam Week',
        iconText: 'E'
    },
    {
        class: 'study-leave-week',
        label: 'Study Leave Week',
        iconText: 'S'
    },
    {
        class: 'holiday-week',
        label: 'Holiday Week',
        iconText: 'H'
    },
    {
        class: 'class-range',
        label: 'Class Timeline',
        iconText: ''
    }
]

export const SLOT_INFO_DAYS = [
    {
        name: 'MO',
        value: 'MONDAY',
        no: 1
    }, {
        name: 'TU',
        value: 'TUESDAY',
        no: 2
    }, {
        name: 'WE',
        value: 'WEDNESDAY',
        no: 3
    }, {
        name: 'TH',
        value: 'THURSDAY',
        no: 4
    }, {
        name: 'FR',
        value: 'FRIDAY',
        no: 5
    }, {
        name: 'SA',
        value: 'SATURDAY',
        no: 6
    }, {
        name: 'SU',
        value: 'SUNDAY',
        no: 7
    }
]

export const LECTUURER_ALLOCATION_TABLE_FOOTER = [
    {
        class: 'lower',
        label: 'Lower than Max Hrs/Week',
        iconText: ''
    },
    {
        class: 'high',
        label: 'Max Hrs/Week Exceeded',
        iconText: ''
    },
    {
        class: 'reached',
        label: 'Max Hrs Reached',
        iconText: ''
    },
    {
        class: 'holiday',
        label: 'Holiday Week',
        iconText: 'H'
    }
]

export const LECTURER_TYPES = [
    {value: 'PART_TIME', label: 'Part Time'},
    {value: 'FULL_TIME', label: 'Full Time'},
    {value: 'VISITING_LEC', label: 'Visiting'}
]

export const TIME_SLOT_TYPES = {
    student: 'STUDENT',
    lecturer: 'LECTURER',
    venue: 'VENUE'
}

export const TIME_PICKER_FORMAT = 'hh:mm:ss'
export const TIME_PICKER_FORMAT2 = 'hh:mm'
export const TIME_PICKER_FORMAT_24 = 'HH:mm'
export const TIME_PICKER_FORMAT_24_SEC = 'HH:mm:00'
export const SLOT_VALIDATION_TIME_FORMAT = 'hh:mm:ss'
export const SLOT_CREATION_TIME_FORMAT = 'HH:mm:00'
export const ATTENDANCE_STATUS = ['PRESENT', 'ABSENT', 'LATE', 'PENDING']

export const viewStudentTypes = {
    schedule: 'SCHEDULE'
}

export const LIST_SECTION = {
    modules: null,
    classes: null,
    course: null,
    assessment: null,
    moduleName: null
}

export const LEAVE_REQUEST_STATUS = [
    {label: 'All', value: 'ALL'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Partially Approved', value: 'PARTIALLY_APPROVED'},
    {label: 'Rejected', value: 'REJECTED'}
]

export const ASSESSMENT_TABLE_STATUS = {
    pending: 'PENDING',
    submitted: 'SUBMITTED',
    moderated: 'MODERATED',
    actual: 'ACTUAL'
}

export const GRADES = {
    a: 'A',
    b: 'B',
    f: 'F'
}

export const EXAM_STATUS = {
    pass: 'PASS',
    fail: 'FAIL',
    absent: 'ABSENT'
}

export const EXAM_STATUS_DROPDOWN = [
    {label: 'Pass', value: 'PASS'},
    {label: 'Fail', value: 'FAIL'},
    {label: 'Absent', value: 'ABSENT'}
]

export const STUDENT_MARK_TYPES = {
    saveAsDraft: 'SAVE_AS_DRAFT',
    submitToHaa: 'SUBMIT_TO_HAA',
    moderatedPublished: 'MODERATED_PUBLISHED',
    actualPublished: 'ACTUAL_PUBLISHED',
    moderatedUnpublished: 'MODERATED_UNPUBLISHED',
    actualUnpublished: 'ACTUAL_UNPUBLISHED'
}

export const ASSESSMENT_MARKING_SHEET_HAA_CSV_HEADER = [
    {label: "CB Number / Uni ID", key: "docketCbNo"},
    {label: "Batch", key: "batch"},
    {label: "First Marks", key: "marks"},
    {label: "Absent", key: "absent"},
    {label: "Moderated Marks", key: "moderatedMarks"},
    {label: "Actual Marks", key: "actualMarks"},
    {label: "Grade", key: "grade"},
    {label: "Exam Status", key: "examStatus"},
    {label: "Publish / Unpublish", key: "publishUnpublish"},
    {label: "Actual Remark", key: "actualRemark"},
    {label: "Actual Remark Date", key: "actualRemarkDate"},
    {label: "Actual Remark User", key: "actualRemarkUser"},
    {label: "Lecturer Remark", key: "lecturerRemark"},
    {label: "Lecturer Remark Date", key: "lecturerRemarkDate"},
    {label: "Lecturer Remark User", key: "lecturerRemarkUser"},
    {label: "Moderator Remark", key: "moderatorRemark"},
    {label: "Moderator Remark Date", key: "moderatorRemarkDate"},
    {label: "Moderator Remark User", key: "moderatorRemarkUser"}
]

export const ASSESSMENT_MARKING_SHEET_HOS_CSV_HEADER = [
    {label: "CB Number / Uni ID", key: "docketCbNo"},
    {label: "Batch", key: "batch"},
    {label: "Marks", key: "marks"},
    {label: "Absent", key: "absent"},
    {label: "Grade", key: "grade"},
    {label: "Exam Status", key: "examStatus"},
    {label: "Lecturer Remark", key: "lecturerRemark"},
    {label: "Lecturer Remark Date", key: "lecturerRemarkDate"},
    {label: "Lecturer Remark User", key: "lecturerRemarkUser"}
]

export const MARKING_VIEW_TYPES = {
    lecturerEnter: {marks: true, absent: true, remark: true, btnImport: true, btnSaveAsDraft: true, btnSubmitHaa: true},
    lecturerView: {marks: false, absent: false, remark: false, lblPassingPercentage: true, tblAllMarks: true},
    haaEnter: {
        marks: true,
        absent: true,
        remark: true,
        btnImport: true,
        btnSaveAsDraft: true,
        returnDate: true,
        copyButton: true,
        btnPublishHaa: true,
        btnUnpublishHaa: true
    }
}

export const HAA_ASSESSMENT_STATUS = [
    {label: 'All', value: null},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Submitted', value: 'SUBMITTED'},
    {label: 'Moderated', value: 'MODERATED'},
    {label: 'Moderated Unpublished', value: 'MODERATED_UNPUBLISHED'},
    {label: 'Moderated Published', value: 'MODERATED_PUBLISHED'},
    {label: 'Actual', value: 'ACTUAL'},
    {label: 'Actual Unpublished', value: 'ACTUAL_UNPUBLISHED'},
    {label: 'Actual Published', value: 'ACTUAL_PUBLISHED'},
    {label: 'Absent', value: 'ABSENT'}
]

export const ASSESSMENT_REMARK_TYPES = [
    {value: 'FIRST', label: 'First'},
    {value: 'MODERATED', label: 'Moderated'},
    {value: 'ACTUAL', label: 'Actual'}
]

export const PUBLISH_UNPUBLISH = {
    published: 'PUBLISHED',
    unPublished: 'UNPUBLISHED'
}

export const ASSESSMENT_MARKING_CSV_TEMPLATE_HEADER = [
    {label: "cbDocketNumber", key: "cbDocketNumber"},
    {label: "marks", key: "marks"},
    {label: "lecturerRemark", key: "lecturerRemark"}
]

export const ASSESSMENT_MARKING_HAA_CSV_TEMPLATE_HEADER = [
    {label: "cbDocketNumber", key: "cbDocketNumber"},
    {label: "submittedMark", key: "submittedMark"},
    {label: "moderatedMark", key: "moderatedMark"},
    {label: "actualMark", key: "actualMark"},
    {label: "remarkTypeValue", key: "remarkTypeValue"}
    // {label: "remarkType", key: "remarkType"}
]

export const ASSESSMENT_TYPES = [
    {label: 'Group', value: 'GROUP'},
    {label: 'Exam', value: 'EXAM'},
    {label: 'Individual', value: 'INDIVIDUAL'},
    {label: 'Group', value: 'GROUP'}
]

export const STUD_ADDRESS = [
    {label: 'Residential', value: 'RESIDENTIAL'},
    {label: 'Permanent', value: 'PERMANENT'}
]

export const STUDENT_COURSES_STATUS = {
    pending: 'PENDING',
    enrolled: 'ENROLLED',
    completed: 'COMPLETED',
    rejected: 'REJECTED',
    ongoing: 'ONGOING'
}

export const STUDENT_MODULE_CSV_HEADER = [
    {label: "Module", key: "moduleName"},
    {label: "Module Type", key: "moduleType"},
    {label: "No Of Credits", key: "noOfCredits"},
    {label: "Average Marks", key: "avgMarks"},
    {label: "Exam Status", key: "examStatus"},
    {label: "Average Attendance", key: "avgAttendance"},
    {label: "Module Status", key: "moduleStatus"}
]

export const STUDENT_RESULTS_CSV_HEADER = [
    {label: "Module", key: "moduleName"},
    {label: "Module Type", key: "moduleType"},
    {label: "Semester", key: "semester"},
    {label: "Attempt", key: "attempt"},
    {label: "Marks", key: "marks"},
    {label: "Grade", key: "grade"},
    {label: "Status", key: "status"}
]

export const STUDENT_ATTENDANCE_CSV_HEADER = [
    {label: "Type", key: "type"},
    {label: "Date", key: "date"},
    {label: "Time Duration", key: "timeDuration"},
    {label: "Venue", key: "venue"},
    {label: "Status", key: "status"}
]

export const STUDENT_ASSESSMENT_RESULT_CSV_HEADER = [
    {label: "Name", key: "name"},
    {label: "Attempt", key: "attempt"},
    {label: "Type", key: "type"},
    {label: "Weight", key: "weight"},
    {label: "First Marks", key: "firstMark"},
    {label: "Moderated Marks", key: "moderatedMark"},
    {label: "Actual Marks", key: "actualMark"}
]

export const STUDENT_QUALIFICATION_STATUS = {
    pending: 'PENDING',
    approved: 'APPROVED',
    rejected: 'REJECTED'
}

export const STUDENT_PROFILE_VIEW_TYPES = {
    changePaymentPlan: 'CHANGE_PAYMENT_PLAN',
    stepperView: 'STEPPER_VIEW'
}

export const STUDENT_FOLLOWUP_STATUS = {
    futureIntake: 'FUTURE_INTAKE',
    followUp: 'FOLLOW_UP'
}

// student master profile edit access roles
export const SMP_EDIT_ACCESS_ROLES = [
    "HEAD_OF_ACADEMIC_ADMIN", "MANAGER_ACADEMIC_ADMIN", "HEAD_OF_FINANCE",
    "FINANCE_MANAGER", "FINANCE_EXECUTIVE", "IT_MANAGER", "HEAD_OF_COUNSELLOR", "COUNSELLOR"
]

export const SMP_FINANCE_EDIT_ACCESS_ROLES = ["HEAD_OF_FINANCE", "FINANCE_MANAGER", "FINANCE_EXECUTIVE"]

export const PROGRESSION_REQUEST_CSV_HEADER = [
    {label: "CB Number", key: "cbNumber"},
    {label: "Name", key: "name"},
    {label: "Course", key: "course"},
    {label: "Batch", key: "batch"},
    {label: "Applied Level", key: "appliedLevel"},
    {label: "Core Modules", key: "coreModules"},
    {label: "Elective Modules", key: "electiveModules"},
    {label: "Study Mode", key: "studyMode"},
    {label: "Remark", key: "remark"}
]

export const PROGRESSION_APPLY_STATUS = {
    approved: 'APPROVED',
    applicable: 'APPLICABLE',
    pending: 'PENDING',
    rejected: 'REJECTED'
}

export const STUDENT_CSV_HEADER = [
    {label: "CB NO.", key: "cbNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Contact", key: "studentMobile"},
    {label: "Email", key: "studentEmail"},
    {label: "Course", key: "courseName"},
    {label: "Batch", key: "batchCode"},
    {label: "Uni Id", key: "uniId"}
]

export const PROGRESSION_ELIGIBLE_CSV_HEADER = [
    {label: "CB Number", key: "cbNumber"},
    {label: "Name", key: "name"},
    {label: "Course", key: "course"},
    {label: "Batch", key: "batch"},
    {label: "Approved Level", key: "approvedLevel"},
    {label: "Status", key: "status"},
    {label: "Core Modules", key: "coreModules"},
    {label: "Elective Modules", key: "electiveModules"},
    {label: "Study Mode", key: "studyMode"},
    {label: "Remark", key: "remark"},
    {label: "Mobile", key: "mobile"},
    {label: "Email", key: "email"}
]

export const ELIGIBLE_STUDENT_STATUS = {
    approved: 'APPROVED',
    pending: 'PENDING',
    rejected: 'REJECTED',
    requested: 'REQUESTED',
    recommended: 'RECOMMENDED',
    progressed: 'PROGRESSED',
    graduated : 'GRADUATED'
}

export const ELIGIBLE_STUDENT_FILTER_STATUS = [
    {label: 'All', value: null},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Not Eligible', value: 'NOT_ELIGIBLE'},
    {label: 'Break', value: 'BREAK'},
    {label: 'Rejected', value: 'REJECTED'},
    {label: 'Approved', value: 'APPROVED'}
]

// export const PROGRESSION_APPROVAL_FILTER_STATUS = [
//     {label: 'All', value: null},
//     {label: 'Pending', value: 'PENDING'},
//     {label: 'Progressed', value: 'PROGRESSED'},
//     {label: 'Recommended', value: 'RECOMMENDED'}
// ]

export const PROGRESSION_APPROVAL_FILTER_STATUS = [
    {label: 'All', value: null},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Progressed', value: 'PROGRESSED'},
    {label: 'Recommended', value: 'RECOMMENDED'},
    // {label: 'Applicable', value: 'APPLICABLE'},
    {label: 'Rejected', value: 'REJECTED'},
    {label: 'Graduated', value: 'GRADUATED'}

    // {label: 'Approved', value: 'APPROVED '}
]

export const PROGRESSION_APPROVAL_CSV_HEADER = [
    {label: "CB Number", key: "cbNumber"},
    {label: "Name", key: "name"},
    {label: "Course", key: "course"},
    {label: "Batch", key: "batch"},
    {label: "Current Level", key: "currentLevel"},
    {label: "Status", key: "status"},
    {label: "Core Modules", key: "coreModules"},
    {label: "Elective Modules", key: "electiveModules"},
    {label: "Study Mode", key: "studyMode"}
]

export const HAA_SPECIAL_APPROVALS_STATUS = [
    {label: 'All', value: null},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Rejected', value: 'REJECTED'},
    {label: 'Recommended', value: 'RECOMMENDED'}
]
export const STUDENT_DROP_STATUS = [
    {label: 'Shared to SSM', value: 'SHARED_TO_SSM'},
    {label: 'Shared to FM', value: 'SHARED_TO_FM'},
    {label: 'Rejected by SSM', value: 'REJECTED_BY_SSM'},
    {label: 'Rejected by FM', value: 'REJECTED_BY_FM'},
    {label: 'Shared to HAA by FM', value: 'SHARED_TO_HAA_BY_FM'},
    {label: 'Rejected', value: 'REJECTED'},
    {label: 'Dropped', value: 'DROPPED'},
    {label: 'Shared to HAA by ST', value: 'SHARED_TO_HAA_BY_ST'},
    {label: 'Rejected by HAA', value: 'REJECTED_BY_HAA'},
    {label: 'Canceled by ST', value: 'CANCELED_BY_STUDENT'}
]

export const STUDENT_DROP = ['SHARED_TO_SSM', 'SHARED_TO_FM', 'REJECTED_BY_SSM', 'REJECTED_BY_FM', 'SHARED_TO_HAA_BY_FM', 'REJECTED', 'DROPPED', 'SHARED_TO_HAA_BY_ST', 'REJECTED_BY_HAA', 'CANCELED_BY_STUDENT']

export const STUDENT_DROP_CSV_HEADER = [
    {label: "CB NO.", key: "cbNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Course", key: "courseName"},
    {label: "Batch", key: "batchCode"},
    {label: "Branch", key: "branchName"},
    {label: "Level", key: "levelName"},
    {label: "Status", key: "status"},
    {label: "Intake", key: "intakeCode"},
    {label: "Payment Plan", key: "paymentPlanName"}
]

export const MANAGE_PROJECTS_CSV_HEADER = [
    {label: "studentName", key: "studentName"},
    {label: "cbNumber", key: "studentId"},
    {label: "course", key: "course"},
    {label: "courseCode", key: "courseCode"},
    {label: "moduleName", key: "moduleName"},
    {label: "moduleCode", key: "moduleCode"},
    {label: "batchCode", key: "batchCode"},
    {label: "status", key: "status"},
    {label: "projectId", key: "projectId"},
    {label: "projectTitle", key: "projectTitle"},
    {label: "supervisorId", key: "supervisorId"},
    {label: "accessorId", key: "accessorId"}
]

export const MANAGE_PROJECTS_STATUS = [
    {label: 'Assigned', value: 'ASSIGNED'},
    {label: 'Unassigned', value: 'UNASSIGNED'}
]

export const MANAGE_PROJECTS_STATUS_TYPES = {
    assigned: 'ASSIGNED',
    unAssigned: 'UNASSIGNED',
    approved: 'APPROVED',
    pending: 'PENDING',
    rejected: 'REJECTED'
}
export const STUDENT_EXIT_STATUS = ['PENDING', 'APPROVED', 'REJECTED']

export const MANAGE_PROJECTS_VIEW_CSV_HEADER = [
    {label: "studentName", key: "studentName"},
    {label: "cbNo", key: "cbNo"},
    {label: "course", key: "course"},
    {label: "moduleName", key: "moduleName"},
    {label: "batchCode", key: "batchCode"},
    {label: "projectTitle", key: "projectTitle"},
    {label: "supervisorName", key: "supervisorName"},
    {label: "accessorName", key: "accessorName"},
    {label: "index", key: "index"},
    {label: "date", key: "date"},
    {label: "reviewedBy", key: "reviewedBy"},
    {label: "status", key: "status"}
]

export const MANAGE_PROJECT_STUDENT_VIEW_CSV_HEADER = [
    {label: "Topic", key: "topic"},
    {label: "Supervisor Name", key: "supervisorName"},
    {label: "Module", key: "module"},
    {label: "Index", key: "index"},
    {label: "Date", key: "date"},
    {label: "Reviewed By", key: "reviewedByUserName"},
    {label: "Status", key: "status"}
]

export const MANAGE_PROJECT_TEMPLATE_CSV_HEADER = [
    {label: "cbNo", key: "cbNo"},
    {label: "projectId", key: "projectId"},
    {label: "projectTitle", key: "projectTitle"},
    {label: "supervisorId", key: "supervisorId"},
    {label: "accessorId", key: "accessorId"}
]

export const MANAGE_PROJECTS_LEC_CSV_HEADER = [
    {label: "studentName", key: "studentName"},
    {label: "cbNumber", key: "studentId"},
    {label: "course", key: "course"},
    {label: "courseCode", key: "courseCode"},
    {label: "moduleName", key: "moduleName"},
    {label: "moduleCode", key: "moduleCode"},
    {label: "batchCode", key: "batchCode"},
    {label: "projectId", key: "projectId"},
    {label: "projectTitle", key: "projectTitle"},
    {label: "supervisorId", key: "supervisorId"},
    {label: "accessorId", key: "accessorId"}
]

export const MY_INQUIRY_STATUS = [
    {label: 'Followup', value: 'FOLLOWUP'},
    {label: 'Future Intake', value: 'FUTURE_INTAKE'},
    {label: 'Not Interested', value: 'NOT_INTERESTED'}
]

export const EXPORT_LECTURER_CSV_HEADER = [
    {label: "lecturerId", key: "value"},
    {label: "lecturerName", key: "label"}
]

export const BATCHES_EXPORT_TEMPLATE_CSV_HEADER = [
    {label: "Intake Code", key: "intakeCode"},
    {label: "Batch Code", key: "batchCode"},
    {label: "Start Date", key: "startDate"},
    {label: "End Date", key: "endDate"},
    {label: "Study Mode", key: "studyMode"}
]

export const ATTENDANCE_EXPORT_TEMPLATE_CVS_HEADER = [
    {label: "Slot Name", key: "slotName"},
    {label: "Lecturer", key: "lecturer"},
    {label: "Venue", key: "venue"},
    {label: "Day", key: "day"},
    {label: "Time Duration", key: "timeDuration"}
]

export const HOS_APPROVALS_SUB_STATUS = [
    {
        value: null,
        label: 'All'
    },
    {
        value: "SEND_TO_HOS",
        label: 'Send to HOS'
    }, {
        value: "HOS_APPROVED",
        label: "HOS Approved"
    }, {
        value: "HOS_REJECTED",
        label: "HOS Rejected"
    }, {
        value: "AA_APPROVED",
        label: "AA Approved"
    }, {
        value: "AA_REJECTED",
        label: "AA Rejected"
    }, {
        value: "FM_APPROVED",
        label: "FM Approved"
    }, {
        value: "FM_REJECTED",
        label: "FM Rejected"
    }, {
        value: "HOF_APPROVED",
        label: "HOF Approved"
    }, {
        value: "HOF_REJECTED",
        label: "HOF Rejected"
    }, {
        value: "SEND_TO_AA",
        label: "Send to AA"
    }, {
        value: "SEND_TO_HOF",
        label: "Send to HOF"
    }, {
        value: "SEND_TO_FM",
        label: "Send to FM"
    }
]

export const SMP_MODULE_STATUS = [
    {
        label: 'Active',
        value: 'ACTIVE'
    }, {
        label: 'Exempted',
        value: 'EXEMPTED'
    }, {
        label: 'Drop',
        value: 'DROP'
    }
]

export const STUDENT_OTHER_INFO_CSV_HEADER = [
    {label: "Name", key: "name"},
    {label: "Type Name", key: "typeName"},
    {label: "Type", key: "type"},
    {label: "Attachment", key: "attachment"}
]

export const COUNSELLOR_SPECIAL_APPROVALS_SUB_STATUS = [
    {
        label: '', value: ''
    }
]

export const HOLIDAY_TYPES = [
    {label: "Public Holiday", value: "PUBLIC"},
    {label: "Study Leave Holiday", value: "STUDY_LEAVE"}
]
export const ACCOUNT_TYPES = [
    {label: "LKR", value: "LKR"},
    {label: "USD", value: "USD"}
]

export const HOLIDAY_CSV_HEADER = [
    {label: "Holiday Name", key: "holidayName"},
    {label: "Holiday Type", key: "holidayType"},
    {label: "Year", key: "year"},
    {label: "From", key: "from"},
    {label: "To", key: "to"}
]

export const COURSE_TRANSFER_REQUEST_STATUS = [
    'PENDING_MAA',
    'MAA_APPROVED',
    'MAA_REJECTED',
    'PENDING_FM',
    'FM_APPROVED',
    'FM_REJECTED',
    'PENDING_HAA',
    'HAA_APPROVED',
    'HAA_REJECTED'
]

export const AMOUNT_TYPE = {
    percentage: "PERCENTAGE",
    fixed: "FIXED"
}

export const MANAGE_PROJECTS_CSV_TEMPLATE_HEADER = [
    {label: "cbNumber", key: "studentId"},
    {label: "projectId", key: "projectId"},
    {label: "projectTitle", key: "projectTitle"},
    {label: "supervisorId", key: "supervisorId"},
    {label: "accessorId", key: "accessorId"}
]

export const CLASS_STATUS = [
    {label: 'Done', value: 'DONE'},
    {label: 'Canceled', value: 'CANCELED'},
    {label: 'On Going', value: 'ON_GOING'},
    {label: 'To Be Continued', value: 'TO_BE_CONTINUED'}
]

export const HOS_APPROVALS_STATUS = [
    {
        value: null,
        label: 'All'
    }, {
        value: "SEND_TO_HOS",
        label: 'Send to HOS'
    }, {
        value: "HOS_RECOMMENDED",
        label: "HOS Recommended"
    }, {
        value: "HOS_REJECTED",
        label: "HOS Rejected"
    }, {
        value: "AA_RECOMMENDED",
        label: "AA Recommended"
    }, {
        value: "AA_REJECTED",
        label: "AA Rejected"
    },
    // {
    //     value: "FM_RECOMMENDED",
    //     label: "FM Recommended"
    // },
    {
        value: "FM_REJECTED",
        label: "FM Rejected"
    }, {
        value: "HOF_RECOMMENDED",
        label: "HOF Recommended"
    }, {
        value: "HOF_REJECTED",
        label: "HOF Rejected"
    }, {
        value: "SEND_TO_AA",
        label: "Send to AA"
    }, {
        value: "SEND_TO_HOF",
        label: "Send to HOF"
    }, {
        value: "SEND_TO_FM",
        label: "Send to FM"
    }
]

export const WORD_COUNT = 250
export const LOGIN_TYPES = {microsoft: 'MICROSOFT', default: 'DEFAULT'}
export const LOGIN_STEPS = {
    login: 'LOGIN',
    forgotPassword: 'FORGOT',
    codeVerify: 'VERIFY',
    resetPassword: 'RESET'
}
export const OTP_LENGTH = 5
export const OTP_TIME = 60

export const STUDENT_ONBOARD_ACCESS_LIST = ['APPLICATION_SUBMITTED', 'OFFER_LETTER_SHARED', 'PENDING_HOS_APPROVAL', 'HOS_APPROVED', 'PENDING_PAYMENT', 'PENDING_ENROLLMENT']

export const WEEK_TYPES = {
    class: 'CLASS',
    holiday: 'HOLIDAY',
    study: 'STUDY',
    examWeek: 'EXAM',
    extraClass: 'EXTRA'
}

export const PROGRESSION_TYPES = ['PROGRESSION_APPROVAL', 'ELIGIBLE_STUDENT', 'PROGRESSION_REQUEST']

export const LEVEL_EXPAND_TYPE = {
    collectPaymentPlan: 'COLLECT_PAYMENT_PLAN',
    feeSchemePaymentPlan: 'FEE_SCHEME_PAYMENT_PLAN',
    feeSchemeDiscount: 'FEE_SCHEME_DISCOUNT'
}

export const KOHA_STATUS = {
    active: 'ACTIVE',
    deActive: 'DEACTIVE'
}

export const STUDENT_ENROLL_CSV_HEADER = [
    {label: "CB NO.", key: "indexNumber"},
    {label: "Student Name", key: "studentName"},
    {label: "Contact", key: "contactNumber"},
    {label: "Course", key: "course"},
    {label: "Batch", key: "batch"},
    {label: "Intake", key: "intakeName"}
]

export const STUDENT_PROGRESSION_FAIL_MESSAGE = 'There is a payment due. Please settle the payment and try again.'